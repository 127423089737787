/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-20 17:30:08
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-26 22:06:23
 */

import Diypackage from './components/diypackage'
import Tagpackage from './components/tagpackage'
import Tradepackage from './components/tradepackage'
import Expandpackage from './components/expandpackage'
import CheckStatus from '@/plugins/checkStatus'
import { mapGetters } from 'vuex'
export default {
  name: 'Datapackage',
  components: {
    Diypackage,
    Tagpackage,
    Tradepackage,
    Expandpackage
  },
  data () {
    return {
      activeTab: 'diy'
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  methods: {
    add () {
      if (new CheckStatus(this.userInfo.status, this.userInfo.avaliable_date).check()) {
        this.$router.push({ path: '/app/datapackage/diy/add' })
      }
    }
  }
}
